export function getDayIndex(day, time) {
    let EVENT_DURATION = 90
    let currentIndex = new Date().getDay();
    let res = 0;
    switch(day){
        case "Monday" : res = 1 - currentIndex; break;
        case "Tuesday" : res = 2 - currentIndex; break;
        case "Wednesday" : res = 3 - currentIndex; break;
        case "Thursday" : res = 4 - currentIndex; break;
        case "Friday" : res = 5 - currentIndex; break;
        case "Saturday" : res = 6 - currentIndex; break;
        case "Sunday" : res = 0 - currentIndex; break;
        default: res = 0; break;
    }
    if(res < 0) res += 7;
    else if(res === 0){
        //if the event time has passed put it at the end
        let now = new Date();
        let today = now.toDateString();
        let eTime = new Date(`${today} ${time}`);
        if(now.getTime() > eTime.getTime() + EVENT_DURATION * 60 * 1000) res = 7;
    }
    return res;
}

export function getNotifyStatus (){
    let notifyStatus = window.localStorage.getItem("cardioFighter.notification");
    return notifyStatus;
}

export function sortByDate(a,b) {
    let res = getDayIndex(a.day, a.time) - getDayIndex(b.day, b.time);

    if(res === 0){
        let aTime = new Date(`2020-01-01 ${a.time}`);
        let bTime = new Date(`2020-01-01 ${b.time}`);
        return aTime.getTime() - bTime.getTime();
    }
    return res;
}

export function parseHash(hash){
    let result = {}
    if(hash && hash.length > 2){
        let hashString = hash.replace("#","")
        let paramParts = hashString.split("&")
        for(let param of paramParts){
            let nvPair = param.split("=")
            if(nvPair.length === 1){
                result[nvPair[0]] = null
            }
            else if(nvPair.length === 2){
                result[nvPair[0]] = nvPair[1]
            }
        }
    }
    return result
}
import axios from 'axios'
import CardioFighterrApiCache from "./CardioFighterApiCache"
import PubSub from 'pubsub-js';
export default class CardioFighterApi{

   
    constructor(props) {
        this.apiUrl = this.getApiUrl();
        this.cache = new CardioFighterrApiCache()
    }

    getApiUrl = () => {
        if(window.location.href.indexOf("http://localhost")===0){
            return "https://devapi.cardiofighter.com";
        }

        return "https://api.cardiofighter.com";
    }

    handleException = (url, exception) => {
        let errorMessage;
        let isServerError = false;
    
        if(exception && exception.response && exception.response.data && exception.response.data.error){
            errorMessage = exception.response.data.error;
            isServerError = exception.response.status === 500;
        }
        else{
            errorMessage = exception.message;
        }
    
        if(errorMessage.toLowerCase().indexOf("network")!==-1){
            errorMessage+=". Please check your Internet and refresh your browser.";
        }
    
        if(isServerError){
            errorMessage+=". Please reload your browser."
        }

        throw new Error(errorMessage);
    } 


    async tryRefresh(){
        let ok = false
        try {
            //try the refresh api, use send without retry to avoid recursion
            let response = await this.send("GET","/authentication/refresh")
            let data = response.data 
            window.localStorage.setItem("cardioFighter.csrf",data.csrfToken)
            axios.defaults.headers.common['x-csrf-token'] = data.csrfToken
            ok = true
            //try to get identity again
        } catch (exception) {
            let ex = exception

            let is401 = ex && ex.response && ex.response.status && ex.response.status === 401
            if(is401) PubSub.publish("LOGGED_OUT")
        }
        return ok
    }

    async send(method, url, data, headers){
        let resp = await axios({
            method: method,
            url: this.apiUrl+url,
            data: data,
            headers: headers,
            timeout: 10 * 1000,
            withCredentials: true
        })
        return resp
    }

    async sendRetry(method, url, data){
        let resp = null
        let ex = null
        let refreshOk = false
        try {
            resp = await this.send(method, url, data)
        } catch (exception) {
            ex = exception

            let is401 = ex && ex.response && ex.response.status && ex.response.status === 401

            if(is401 && 
                url!=="/authentication/refresh" &&
                url.indexOf("/content") === -1
            ){
                //session expired try refresh token
                refreshOk = await this.tryRefresh()
            }
        }

        if(refreshOk){
            resp = await this.send(method, url, data) 
            ex = null
        }
        if(ex){
            throw ex
        }
        return resp
    }


    async getFresh(url){
        let ex = null
        let resp = null
        try {
            resp = await this.sendRetry("GET", url)
        } catch (exception) {
            ex = exception
        }

        if(ex){
            this.handleException(url, ex);
        }
        else{
            this.cache.set(url,resp)
        }   
        return resp
    }

    get = async(url) => {
        let resp = this.cache.get(url)
        
        if(resp){
            if(resp.stale) this.getFresh(url) //get new one async
            return resp
        } 

        resp = await this.getFresh(url)

        return resp;
    }

    put = async(url,data) => {
        let resp = null;
        try {
            resp = await this.sendRetry("PUT", url, data)
        } catch (exception) {
            this.handleException(url, exception);
        }
        return resp;
    }

    post = async(url,data) => {
        let resp = null;
        try {
            resp = await this.sendRetry("POST", url, data)
        } catch (exception) {
            this.handleException(url, exception);
        }
        return resp;
    }

    delete = async(url) => {
        let resp = null;
        try {
            resp = await this.sendRetry("DELETE", url)
        } catch (exception) {
            this.handleException(url, exception);
        }
        return resp;
    }

    /**
     * Enables or disables sharing leaderboard results for a user
     * @param {boolean} enabled 
     */
    enableLeaderboard = async(enabled) => {
        let config = "no";
        if(enabled) config = "yes";
        await this.post("/leaderboard",{"enableLeaderboard":config});
    }

    /**
     * Gets the current user profile
     */
    getProfile = async() => {
        let response = await this.get("/profile");
        //save it
        window.localStorage.setItem("cardioFighter.identity", JSON.stringify(response.data));

    }

    /**
     * Cleares out cached profile resources and gets a new profile info from the server
     */
    refreshProfile(){
        this.cache.delete("/profile")
        this.cache.delete("/leaderboard")
        this.getProfile()
    }

     /**
     * Updates the nickname for a user
     * @param {string} nickname 
     */
    updateNickname = async(nickname) => {
        await this.post("/profile",{"nickname":nickname});
        await this.refreshProfile()
    }

     /**
     * Updates the goal cardio points for a user
     * @param {string} activityGoal 
     */
    updateActivityGoal = async(activityGoal) => {
        await this.post("/profile",{"activityGoal":activityGoal});
        await this.refreshProfile()
    }


    /**
     * Updates the playlist progress
     * @param {Object} progress 
     */
    updatePlaylistProgress = async(playingList) => {
        await this.post("/profile",{"playingList":playingList});
        await this.refreshProfile()
    }

    /**
     * Calls the attendance API
     * @param {string} eventId 
     */
    attendEvent = async(eventId) => {
        await this.post("/attend", {"eventId":eventId});
        await this.refreshProfile()
    }

    /**
     * Allows users to send messages in chat
     * @param {string} text 
     */
    sendMessage = async(text) => {
        await this.post("/message",{"messageText":text});
    }

    /**
     * Allows users to send messages in chat
     * @param {string} text 
     */
     deleteMessage = async(timeStamp) => {
        try {
            await this.delete("/message/"+timeStamp);

        } catch  {
            
        }
    }

    /**
     * Puts an item in the playlist
     * @param {string} recordId
     * @param {number} ord 
     */
    addToPlaylist = async(recordId, ord) => {
        await this.post("/playlist",{"recordId":recordId,"ord":ord});
    }

    /**
     * Removes an item from the playlist
     * @param {string} recordId
     * @param {number} ord 
     */
    removeFromPlaylist = async(recordId) => {
        await this.delete("/playlist/"+recordId);
    }

    /**
     * Changes the order of a playlist item
     * @param {string} recordId
     * @param {number} ord 
     */
    moveInPlaylist = async(recordId, ord) => {
        await this.put("/playlist", {"recordId":recordId,"ord":ord});
    }



    /**
     * Calls the merch API
     * @param {string} code 
     */
     claimMerch = async(code) => {
        try {
            await this.post("/merch", {"merchPromoCode":code});
        } catch {
            //ignore error
        }
    }

    preload = async() => {
        try {
            await this.get("/content/event")
            await this.get("/content/event/free-fight-lift-sun")
            await this.get("/content/event/free-fight-lift-thu")
            await this.get("/content/event/free-pound-zumba")
            await this.get("/content/event/free-zumba-pound")
            //await this.get("/playlist/builtin/playlist-fight-fall")
            //await this.get("/playlist")


        } catch  {
            
        }
    }

}
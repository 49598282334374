import axios from 'axios';
import CardioFighterComponent from './CardioFighterComponent';

export default class SubscriptionChecker extends CardioFighterComponent
{
    isSubExpired = (subList) => {
    //check expiration
        for(let sub of subList){
            let expiry = new Date(sub.expiry).getTime();
            let now = new Date().getTime();
            if(now<expiry) return false;
        }
        return true;
    }

    async initSubStatus(){
        let subMissing = false;
        let subExpired = false; 
        let identity = this.getIdentity();
        if(identity){
          let subList = identity.subscriptionList;

          if(!subList || subList.length === 0) subMissing = true;
          else subExpired = this.isSubExpired(subList);
          
          if(subMissing || subExpired){
            //check if there's a code
    
            let subCode = window.localStorage.getItem("cardioFighter.subCode");
            let subType = window.localStorage.getItem("cardioFighter.subType");
    
            if(subCode && subType){
              try {
                let r1 = await axios.post(this.apiUrl+"/subscription",{"subCode":subCode,"subType":subType},{withCredentials:true});
    
                window.localStorage.removeItem("cardioFighter.subCode");
                window.localStorage.removeItem("cardioFighter.subType");
    
                let csrfToken = r1.data.csrfToken;
                window.localStorage.setItem("cardioFighter.csrf",csrfToken);
                axios.defaults.headers.common['x-csrf-token'] = csrfToken;
                window.location.reload();
                
                return;
              } catch (error) {
                console.log(error);
              }
            }
          }
        }
    
        window.subStatus = { 
            subMissing:subMissing, 
            subExpired:subExpired
          };
    }
}
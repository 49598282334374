import React from 'react';
import CardioFighterApi from './CardioFighterApi';
import deepEqual from 'deep-equal';



export default class CardioFighterComponent extends React.Component {
  
  constructor(props) {
    super(props);
    this.api = new CardioFighterApi();
    if(window.subStatus){
      this.subOk = window.subStatus.subMissing === false && window.subStatus.subExpired === false;
    }
    this.apiUrl = this.api.getApiUrl();
    this.hash = "";
    if(window.location.hash && window.location.hash.length > 0){
      let hash = decodeURIComponent(window.location.hash.substring(1));
      //validate hash
      if(hash.match(/^[\w\s\d&=.-]+$/)) this.hash = hash;
    }
    this.contentSrc = "https://content.cardiofighter.com";
    this.contentApi = `/content`;
    let parentContentVersion;
    if(props) parentContentVersion = props.contentVersion;
    let identity = this.getIdentity();
    this.state = {
      content: null,
      loadError: null,
      contentVersion: 0,
      parentContentVersion: parentContentVersion,
      identity: identity
    };

    if(props && props.content) {
      Object.assign(this.state,
      {
          content: props.content
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.contentId !== state.prevContentId || 
      (props.contentVersion && props.contentVersion !== state.parentContentVersion)) {
      return {
        content: null,
        prevContentId: props.contentId,
        parentContentVersion: props.contentVersion
      };
    }
    // No state update necessary
    return null;
  }

  onContentLoaded = async(content) => {/*Do something with the content*/ return content;}

  loadContent = async () => {  
    try{
      if(this.contentType){
        let ctId = this.props.contentId;
        let content = await this.loadContentWithId(this.contentType, ctId);
        content = await this.onContentLoaded(content);
        let contentVersion = this.state.contentVersion;
        if(!deepEqual(content, this.state.content)){
          contentVersion++;
        }

        if(this.updateStateOnLoad){
          this.setState({
            content:content,
            contentVersion: contentVersion,
            loadError:null
          });
        }
      }
    }
    catch(err){
      if(this.updateStateOnLoad) this.setState({loadError:err});
    }
  }

  async loadContentWithId(contentType, ctId){  
   
    let ctUrl = "";
    if(ctId) ctUrl = `${this.contentApi}/${contentType}/${ctId}`;
    else ctUrl = `${this.contentApi}/${contentType}`;
    let response = await this.api.get(ctUrl);
    return response.data;

  }

  getPrecision(number){
    if(isNaN(number)) return number;
    if(number < 1000) return number
    return (number/1000).toPrecision(2) + "K"
  }

  getIdentity(){
    let identityString;
    if(window && window.localStorage){
      identityString = window.localStorage.getItem("cardioFighter.identity");
    }
    if(identityString){
      let identity = JSON.parse(identityString);
      let cardioPoints = identity.cardioPoints;
      if(identity.pointsInfo) cardioPoints = identity.pointsInfo.totalPoints;
      identity.cardioPoints = this.getPrecision(cardioPoints)
      return identity;
    }
  }

  getProvider = () => {
    return "google"
    /*let idp = window.localStorage.getItem("cardioFighter.idp");
    if(idp === null){
      let identity = this.getIdentity();
      if(identity){
        idp = identity.provider;
      }
      else if(document.location.href.indexOf("fbclid") > -1){
        return "facebook";
      }
    }
    return idp;*/
  }

  isValidProvider = (idp) => {
    return idp === "facebook" || idp === "google";
  }

  getLogin = () => {
    console.log(document.referrer)
    let idp = this.getProvider();
    if(this.isValidProvider(idp)){
      return this.apiUrl+"/authentication/signin/"+idp;
    }
    else{
      return "/login";
    }
  }

  switchAccount = () => {
    let idp = this.getProvider();
    if(this.isValidProvider(idp)){
      let provider = idp === 'google' ? 'facebook': 'google';
      window.location = `${this.apiUrl}/authentication/signin/${provider}`;
      window.localStorage.setItem("cardioFighter.idp", provider);
    }
  }

  doLogin = (idp) => {
    if(this.isValidProvider(idp)){
      window.localStorage.setItem("cardioFighter.idp", idp);
      window.location = this.apiUrl+"/authentication/signin/"+idp;
    }
  }

  updateIdentity(identity){
    if(window && window.localStorage){
      window.localStorage.setItem("cardioFighter.identity", JSON.stringify(identity));
    }
  }

  componentDidMount() {
    this.updateStateOnLoad = true;
    this.loadContent();
  }

  componentDidUpdate(prevProps, prevState) {
    let propsChanged = !deepEqual(prevProps,this.props);
    if (propsChanged || (this.state.content===null && this.state.loadError === null)) {
      this.loadContent();
    }
  }

  componentWillUnmount() {
    this.updateStateOnLoad = false;
  }

  getMinutesAndSeconds(time){
    if(!time) return "0:00";
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    if(seconds <10) seconds = "0" + seconds;
    return `${minutes}:${seconds}`;
  }
  
}
export default class CardioFighterApiCache{
    cacheDuration = {
        "/playlist/own": 0,
        "/playlist/builtin": 15 * 60 * 1000,
        "/message": 0,
        "/content": 15 * 60 * 1000,
        "/leaderboard": 5 * 60 * 1000,
        "/profile": 5 * 1000
    }

    constructor(props) {
        if(!document.cfCache) document.cfCache = {} //init simple CF cache object
    }

    getDuration(url){
        let keys = Object.keys(this.cacheDuration)
        for(let key of keys){
            if(url.indexOf(key) === 0) return this.cacheDuration[key]
        }
        return 0
    }

    get(url){
        let now = new Date().getTime()
        const cached = document.cfCache[url]
        if(cached && cached.time && cached.resp){
            let diff = now - cached.time
            let duration = this.getDuration(url)
            if(diff < duration){
                console.debug("Returning cached response for: "+url)
            }
            else{
                console.debug("Returning stale response for: "+url)
                cached.resp.stale = true
            }
            return cached.resp
        }

        return null
    }
    set(url, resp){
        if(resp && resp.status === 200){
            //cache the response
            let now = new Date().getTime()
            let duration = this.getDuration(url)
            if(duration>0){
                document.cfCache[url] = {
                    time: now,
                    resp: resp
                }
            }
        }
        else{
            console.log("Cannot cache response")
        }
    }

    delete(url){
        let keys = Object.keys(document.cfCache)
        for(let key of keys){
            if(key.indexOf(url) === 0)
                document.cfCache[key] = null
        }
    }

}
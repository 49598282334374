/**
 * Copyright CardioFighter.com 2021 All Rights Reserved
 **/
import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import CardioFighterComponent from './CardioFighterComponent';
import SubscriptionChecker from './SubscriptionChecker';
import ReactLoading from "react-loading"
import axios from 'axios';
import { parseHash } from './utils';
import PubSub from 'pubsub-js';
const FrontPage = lazy(() => import('./FrontPage'));
const LoginPage = lazy(() => import('./LoginPage'));
const PublicPage = lazy(() => import('./PublicPage'));
const HomePage = lazy(() => import('./HomePage'));
const LibraryPage = lazy(() => import('./LibraryPage'));
const LeaderboardPage = lazy(() => import('./LeaderboardPage'));
const PlaylistPage = lazy(() => import('./PlaylistPage'));
const ClassesPage = lazy(() => import('./ClassesPage'));
const ContentPage = lazy(() => import('./ContentPage'));



export default class App extends CardioFighterComponent{
  
  constructor(props) {
    super(props);
    PubSub.subscribe("LOGGED_OUT", this.setLogout);

  }

  setLogout = () => {
    if(this.state && this.state.loaded && this.state.loggedIn) this.setState({loggedIn: false})
  }

  parsedHash = null

  loadMarkup = <div className="front-container">
        <div style={{height:55}}></div>
        <div className="front-inner-container d-flex justify-content-center">
        <h1 className="front">
        <ReactLoading type="spinningBubbles" height={200} width={200} color="#ffffff"></ReactLoading>
        </h1>
      </div>
    </div>

  async loadIdentity(){

    //load the CSRF token from the hash if it's there
    let csrfToken;

    this.parsedHash = parseHash(this.hash)
    window.location.hash = "";

    if(this.parsedHash.code){
      let code = this.parsedHash.code
      window.localStorage.setItem("cardioFighter.subCode",code);
      window.localStorage.setItem("cardioFighter.subType","promo");
    }

    if(this.parsedHash.csrfToken){
      csrfToken = this.parsedHash.csrfToken
      window.localStorage.setItem("cardioFighter.csrf",csrfToken);
    }
    else{
      //try to load the token from browser storage
      csrfToken = window.localStorage.getItem("cardioFighter.csrf");
    }
    if(csrfToken!==null){
      axios.defaults.headers.common['x-csrf-token'] = csrfToken;
    }


    let ok =false;
    try{
      await this.api.getProfile()
      ok = true;
      await this.api.preload()
    }
    catch(err){
      //not logged in      
    }
    return ok;
  }

  
  async componentDidMount(){
    let isLoggedIn = await this.loadIdentity();

    let subChecker = new SubscriptionChecker();
    await subChecker.initSubStatus();
    
    this.setState({
      loggedIn: isLoggedIn,
      loaded: true
    });

    //reload once a day to get updates
    setTimeout(()=>{window.location.reload()}, 24 * 60 * 60 * 1000)

  }

  renderLoggedInPage(loggedIn, markup){
    if(loggedIn === true){
      let redirect = window.localStorage.getItem("cardioFighter.redirect");
      if(redirect){
        window.localStorage.removeItem("cardioFighter.redirect");
        let curHref = window.location.href.replace("#","")
        if(curHref !== redirect){
          window.location = redirect
          return this.loadMarkup
        } 
      }
      return markup;
    }
    //save current document location to redirect later
    if(window.location.pathname.indexOf("/home/")===0){
      window.localStorage.setItem("cardioFighter.redirect",document.location.href);
    }
    let isCallback = this.parsedHash && this.parsedHash.csrfToken
    if(isCallback) window.location = "/login" //if it's callback it means that the browser cannot get access to session ids
    else window.location = this.getLogin();

    return this.loadMarkup
   
  }
 
  render() { 
    if(!this.state || !this.state.loaded) return this.loadMarkup
    let loggedIn = this.state.loggedIn;

    return <>
    <Router>
      <Suspense fallback={this.loadMarkup}>
        <Route path="/" exact={true} render={()=>{
          if(loggedIn) return this.renderLoggedInPage(loggedIn,<HomePage></HomePage>)
          else return <FrontPage></FrontPage>
        }}/> 
        <Route path="/login" exact={true} component={LoginPage} /> 
        <Route path="/legal" exact={true} 
          render={() => (<PublicPage title="Legal" mdFile="/legal2.md"></PublicPage>)}>
        </Route>
        <Route path="/support" exact={true} 
          render={() => (<PublicPage title="Support" mdFile="/support.md"></PublicPage>)}>
        </Route>
        <Route path="/error" exact={true} 
          render={() => (<PublicPage title="Error" message={this.hash}></PublicPage>)}>
        </Route>
        <Route path="/home/content/:contentId" exact={true} 
          render={({match})=>{return this.renderLoggedInPage(loggedIn,<ContentPage match={match}></ContentPage>)}}>
        </Route>
        <Route path="/home" exact={true} 
          render={()=>{return this.renderLoggedInPage(loggedIn,<HomePage></HomePage>)}}>
        </Route>
        <Route path="/home/classes" exact={true} 
          render={()=>{return this.renderLoggedInPage(loggedIn,<ClassesPage></ClassesPage>)}}>
        </Route>
        <Route path="/home/leaderboard" exact={true} 
          render={()=>{return this.renderLoggedInPage(loggedIn,<LeaderboardPage></LeaderboardPage>)}}>
        </Route>
        <Route path="/home/library" exact={true} 
          render={()=>{return this.renderLoggedInPage(loggedIn,<LibraryPage></LibraryPage>)}}>
        </Route>
        <Route path="/home/playlist/:playlistId" exact={true} 
          render={({match})=>{return this.renderLoggedInPage(loggedIn,<PlaylistPage match={match}></PlaylistPage>)}}>
        </Route>
      </Suspense>
    </Router>

    <div id="agreementDiv" className="hidden"></div>
    <div id="instructorDiv" className="hidden"></div>
    <div id="subscriptionDiv" className="hidden"></div>
    <div id="playerDiv" className="hidden"></div>
    <div id="zoomDiv" className="hidden"></div>
    <div id="nicknameDiv" className="hidden"></div>
    <div id="leaderboardPromptDiv" className="hidden"></div>
    <div id="clubPromptDiv" className="hidden"></div>



    </>
  }
}

